import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Flex } from 'rebass';
import {
  borderBottom,
  position,
  display,
  color,
  space,
  boxShadow,
} from 'styled-system';
import { FiChevronDown } from 'react-icons/fi';
import { Manager as PopperManager, Popper, Reference } from 'react-popper';
import CoreText from '../../../Text';
import Link from '../../../Link';
import { getUrlFromPath } from '../utils';

const Text = styled(CoreText)`
  text-transform: uppercase;
`;

const Item = styled(Flex)`
  ${borderBottom}
  ${({ isOpen, theme, isLast, isTransparent, hasChildren }) => css`
    opacity: ${isTransparent ? 0.3 : 1};
    display: flex;
    justify-content: center;
    border-bottom: ${isLast || isOpen ? 0 : theme.border};
    align-items: center;
    justify-content: ${hasChildren ? 'space-between' : 'center'};
    transition: 0.1s;
    cursor: pointer;
    color: ${isOpen ? theme.colors.chestnutRose : 'initial'};
    position: relative;
    &:after {
      position: absolute;
      width: 100%;
      height: 16px;
      bottom: 0;
      left: 0;
      content: '';
    }
  `}
`;
Item.defaultProps = {
  borderBottom: ['initial', null, null, 0],
  as: 'li',
};

const Icon = styled(FiChevronDown)`
  ${({ isOpen }) => css`
    transition: 0.1s;
    transform: rotate(${isOpen ? '180deg' : 0});
  `}
`;

const Children = styled(Flex)`
  ${position}
  ${space}
  ${display}
  ${color}
  ${boxShadow}
  ${({ isOpen, height, theme }) => css`
    position: relative;
    flex-direction: column;
    align-items: center;
    transition: 0.2s;
    transform-origin: top;
    /* max-height should be greater than what the height the menu will ever have */
    height: ${isOpen ? `${height}px` : 0};
    opacity: ${isOpen ? 1 : 0};
    pointer-events: ${isOpen ? 'auto' : 'none'};
    border-radius: ${theme.borderRadius};
    overflow: hidden;
  `}
`;
Children.defaultProps = {
  as: 'ul',
  boxShadow: [null, null, null, '0 0 16px 0 rgba(0, 0, 0, 0.16)'],
};

const ChildItem = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.chestnutRose};
    transition: 0.1s ease;
    &:hover {
      background: ${theme.grays['200']};
    }
  `}
`;

const MenuItem = ({
  value,
  isLast,
  isOpen,
  isTransparent,
  onToggleMenu,
  ...props
}) => {
  const [childrenHeight, setChildrenHeight] = useState(0);
  const childrenRef = useRef();
  const hasChildren = value.items && !!value.items.length;
  const iconSize = 16;
  useEffect(() => {
    if (childrenRef.current)
      setChildrenHeight(childrenRef.current.scrollHeight);
    return () => {
      setChildrenHeight(0);
    };
  }, [childrenRef, childrenRef.current, isOpen]);
  const { label, path } = value.primary;
  const url = getUrlFromPath(path);
  return (
    <PopperManager>
      <Reference>
        {({ ref }) => (
          <Item
            ref={ref}
            isOpen={isOpen}
            isLast={isLast}
            isTransparent={isTransparent}
            hasChildren={hasChildren}
            onClick={hasChildren ? onToggleMenu : undefined}
            onMouseEnter={hasChildren ? onToggleMenu : undefined}
            {...props}
          >
            {hasChildren ? (
              <>
                <div style={{ width: iconSize }}>&nbsp;</div>
                <Text>{label}</Text>
                <Icon size={iconSize} isOpen={isOpen} />
              </>
            ) : (
              <Link to={url || undefined} target={path.target}>
                <Text>{label}</Text>
              </Link>
            )}
          </Item>
        )}
      </Reference>
      {hasChildren && (
        <>
          <Popper placement="bottom-left">
            {({ ref, style }) => (
              <Children
                ref={ref}
                style={{ ...style, zIndex: 128 }}
                display={['none', null, null, 'initial']}
                isOpen={isOpen}
                backgroundColor="white"
              >
                {value.items.map(item => {
                  const { label, path } = item;
                  const url = getUrlFromPath(path);
                  return (
                    <Link
                      to={url}
                      target={path.target}
                      key={`${value.primary.label}-child-${label}`}
                    >
                      <ChildItem py={3} px={3}>
                        {label}
                      </ChildItem>
                    </Link>
                  );
                })}
              </Children>
            )}
          </Popper>
          <Children
            display={[null, null, null, 'none']}
            // py={isOpen ? 2 : 0}
            height={childrenHeight}
            ref={childrenRef}
            isOpen={isOpen}
          >
            {value.items.map(item => {
              const { label, path } = item;
              const url = getUrlFromPath(path);
              return (
                <Link
                  target={path.target}
                  key={`${value.primary.label}-plain-child-${label}`}
                  to={url}
                >
                  <Text py={2}>{label}</Text>
                </Link>
              );
            })}
          </Children>
        </>
      )}
    </PopperManager>
  );
};

MenuItem.propTypes = {
  value: PropTypes.object.isRequired,
};

export default MenuItem;

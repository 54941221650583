export const getUrlFromPath = path => {
  switch (path.link_type) {
    case 'Document':
      return `http://localhost/${path.uid}`;
    case 'Web':
      return path.url;
    default:
      return path.url;
  }
};

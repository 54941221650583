import React from 'react';
import { Flex, Box } from 'rebass';
import { graphql, useStaticQuery } from 'gatsby';

const SocialMenu = () => {
  const { allPrismicMenus } = useStaticQuery(graphql`
    {
      allPrismicMenus {
        nodes {
          data {
            social_links {
              icon {
                url
              }
              name
              link {
                url
                target
              }
            }
          }
        }
      }
    }
  `);
  return (
    <Flex m={-2}>
      {allPrismicMenus?.nodes?.[0]?.data?.social_links?.map(item => {
        return (
          <Box p={2} key={`social-menu-${item.name}`}>
            <a href={item.link.url} target={item.link.target}>
              <img width="16" src={item.icon.url} />
            </a>
          </Box>
        );
      })}
    </Flex>
  );
};

export default SocialMenu;

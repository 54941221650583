import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from 'rebass';
import styled from 'styled-components';
import Header from './Header';
import Footer from './Footer';
import HeaderHeightContext from './context';
import Headroom from 'react-headroom';

const Wrapper = styled(Flex)`
  flex-direction: column;
  min-height: 100vh;
`;

const Content = styled(Box)`
  flex: 1;
  background: ${({ theme }) => theme.background({ theme, variant: 'primary' })};
`;

const Layout = ({ children, onHeaderHeight, ...props }) => {
  const [headerHeight, setHeaderHeight] = useState(147);
  const ref = useRef();
  const _setHeaderHeight = () => {
    if (ref.current) {
      const height = ref.current.scrollHeight;
      if (typeof onHeaderHeight === 'function') onHeaderHeight(height);
      setHeaderHeight(height);
    }
  };
  useEffect(() => {
    _setHeaderHeight();
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', _setHeaderHeight);
    }
    return () => {
      window.removeEventListener('resize', _setHeaderHeight);
    };
  }, [ref, ref.current]);

  return (
    <HeaderHeightContext.Provider value={headerHeight}>
      <Wrapper {...props}>
        <Headroom style={{ zIndex: 4 }} upTolerance={100}>
          <Header ref={ref} />
        </Headroom>
        <Content>{children}</Content>
        <Footer />
      </Wrapper>
    </HeaderHeightContext.Provider>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;

import React, { useState } from 'react';
import { display } from 'styled-system';
import styled from 'styled-components';
import { Box, Flex } from 'rebass';

import Button from '../../../Button';
import MenuItem from './Item';
import Link from '../../../Link';
import { useStaticQuery, graphql } from 'gatsby';
import { getUrlFromPath } from '../utils';
import TranslateButton from '../Translate';

const Wrapper = styled(Flex)`
  ${display}
  align-items: center;
`;
Wrapper.defaultProps = {
  as: 'ul',
  justifyContent: ['center', null, null, 'flex-end'],
  flexDirection: ['column', null, null, 'row'],
};

const BoxWithDisplay = styled(Box)`
  ${display}
`;

const Menu = ({ showCta, ...props }) => {
  const { allPrismicMenus } = useStaticQuery(graphql`
    {
      allPrismicMenus {
        nodes {
          data {
            button_text
            button_link {
              url
              link_type
              target
            }
            body {
              ... on PrismicMenusBodyMenu {
                primary {
                  label
                  path {
                    url
                    uid
                    link_type
                    target
                  }
                }
                items {
                  label
                  path {
                    url
                    uid
                    link_type
                    target
                  }
                  label
                  path {
                    url
                    uid
                    link_type
                    target
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const [openedMenu, onToggleMenu] = useState(null);
  return (
    <Wrapper m={[-3]} onMouseLeave={() => onToggleMenu(null)} {...props}>
      {allPrismicMenus.nodes?.[0]?.data?.body?.map((i, idx, arr) => {
        const isOpen = openedMenu === idx;
        const onToggle = value => {
          if (value === 'close') {
            onToggleMenu(null);
          } else {
            onToggleMenu(idx);
          }
        };
        const isTransparent = !isOpen && openedMenu !== null;
        const isLast = idx === arr.length - 1;
        return (
          <MenuItem
            p={3}
            key={`primary-menu-${i.primary.label}`}
            isTransparent={isTransparent}
            isLast={isLast}
            value={i}
            isOpen={isOpen}
            onToggleMenu={onToggle}
          />
        );
      })}
      {showCta && (
        <Box p={2}>
          <Link
            to={getUrlFromPath(allPrismicMenus.nodes?.[0]?.data?.button_link)}
            target={allPrismicMenus.nodes?.[0]?.data?.button_link.target}
          >
            <Button variant="secondary" width={1}>
              {allPrismicMenus.nodes?.[0]?.data?.button_text}
            </Button>
          </Link>
        </Box>
      )}
      <BoxWithDisplay display={['none', null, null, 'inherit']} p={1}>
        <TranslateButton withTooltip />
      </BoxWithDisplay>
      <BoxWithDisplay
        display={['flex', null, null, 'none']}
        css={{ justifyContent: 'center' }}
        as="li"
        p={3}
      >
        <TranslateButton />
      </BoxWithDisplay>
    </Wrapper>
  );
};

Menu.defaultProps = {
  showCta: true,
};

export default Menu;

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { getUrlFromPath } from '../utils';
import Link from '../../../Link';
import { Flex } from 'rebass';

const MenuSitemap = () => {
  const { allPrismicMenus } = useStaticQuery(graphql`
    {
      allPrismicMenus {
        nodes {
          data {
            button_text
            button_link {
              url
              link_type
              target
            }
            body {
              ... on PrismicMenusBodyMenu {
                primary {
                  label
                  path {
                    url
                    uid
                    link_type
                    target
                  }
                }
                items {
                  label
                  path {
                    url
                    uid
                    link_type
                    target
                  }
                  label
                  path {
                    url
                    uid
                    link_type
                    target
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  return (
    <Flex m={[-3, null, -4]} flexWrap="wrap">
      {allPrismicMenus.nodes?.[0]?.data?.body?.map((item, idx) => {
        const { label, path } = item.primary;
        const { items } = item;
        const url = getUrlFromPath(path);
        return (
          <Flex
            width={[1, null, 'auto']}
            m={-2}
            flexDirection="column"
            p={[3, null, null, 4]}
            key={`sitemap-item-${label}-${idx}`}
          >
            <Link fontSize={[2, null, 1]} m={2} target={path.target} to={url}>
              {label}
            </Link>
            {items.map(child => {
              const { label, path } = child;
              const url = getUrlFromPath(path);
              return (
                <Link
                  m={2}
                  fontSize={[2, null, 1]}
                  key={`sitemap-children-${label}-${idx}`}
                  target={path.target}
                  to={url}
                >
                  {label}
                </Link>
              );
            })}
          </Flex>
        );
      })}
    </Flex>
  );
};

export default MenuSitemap;

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const Logo = props => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(width: 220) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  return (
    <Img
      placeholderStyle={{ opacity: 0 }}
      fixed={data.logo.childImageSharp.fixed}
      imgStyle={{ objectFit: 'contain', objectPosition: 'left center' }}
      {...props}
    />
  );
};

export default Logo;

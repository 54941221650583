import React from 'react';
import styled, { css } from 'styled-components';
import { Flex, Box } from 'rebass';
import Link from '../../../Link';
import Container from '../../../Container';
import Text from '../../../Text';
import { useStaticQuery, graphql } from 'gatsby';
import { getUrlFromPath } from '../utils';

const StyledMenu = styled(Box).attrs(() => ({
  py: 4,
}))`
  ${({ theme }) => css`
    background-color: ${theme.grays['800']};
  `}
`;

const SecondaryMenu = () => {
  const { allPrismicMenus } = useStaticQuery(graphql`
    {
      allPrismicMenus {
        nodes {
          data {
            secondary_menus {
              label
              link {
                link_type
                url
                uid
                target
              }
            }
          }
        }
      }
    }
  `);
  return (
    <StyledMenu>
      <Container>
        <Flex
          m={-3}
          alignItems="center"
          flexDirection={['column', null, 'row']}
          justifyContent="center"
        >
          {allPrismicMenus?.nodes?.[0]?.data?.secondary_menus?.map(item => (
            <Box p={3} key={`secondary-menu-${item.label}`}>
              <Link to={getUrlFromPath(item.link)} target={item.link.target}>
                <Text color="white" style={{ textTransform: 'uppercase' }}>
                  {item.label}
                </Text>
              </Link>
            </Box>
          ))}
        </Flex>
      </Container>
    </StyledMenu>
  );
};

export default SecondaryMenu;

import React, { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import {
  Tooltip,
  useTooltipState,
  TooltipReference,
  TooltipArrow,
} from 'reakit/Tooltip';

import Text from '../../../Text';

const TranslateText = ({ color, fontSize }) => (
  <Text
    variant="secondary"
    css={{
      textTransform: 'uppercase',
    }}
    color={color}
    fontSize={fontSize}
  >
    Translate
  </Text>
);

const BROWSER_LANGUAGE =
  typeof window !== 'undefined'
    ? window.navigator.language.split('-')[0]
    : 'es';

const TranslateButton = ({ withTooltip }) => {
  const [href, setHref] = useState(null);
  const location = useLocation();
  useEffect(() => {
    setHref(location.href);
  }, [location.pathname]);
  const tooltip = useTooltipState({ modal: true });
  return (
    <>
      <Tooltip
        {...tooltip}
        css={{ background: 'black', padding: 8, borderRadius: 4, zIndex: 10 }}
        {...(!withTooltip ? { visible: false } : {})}
      >
        <TooltipArrow {...tooltip} />
        <TranslateText fontSize={0} color="white" />
      </Tooltip>
      <TooltipReference
        as="a"
        href={`https://translate.google.com/translate?sl=auto&tl=${BROWSER_LANGUAGE}&u=${href}`}
        style={{
          display: 'inline-flex',
          alignItems: 'center',
        }}
        {...tooltip}
      >
        {!withTooltip && (
          <>
            <Text
              variant="secondary"
              css={{
                textTransform: 'uppercase',
              }}
            >
              Translate
            </Text>
            &nbsp;
          </>
        )}
        <svg
          aria-hidden="true"
          focusable="false"
          data-icon="language"
          role="img"
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ width: 24 }}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.96621 4.35882C9.2696 3.54975 10.4184 3.54958 10.7218 4.35882L13.5344 11.8588C13.7162 12.3436 13.4706 12.884 12.9858 13.0658C12.501 13.2475 11.9606 13.0021 11.7788 12.5172L11.3037 11.2505H8.38437L7.90935 12.5172C7.72753 13.002 7.18718 13.2476 6.70232 13.0658C6.21751 12.884 5.97189 12.3436 6.1537 11.8588L8.96621 4.35882ZM9.84406 7.358L9.08749 9.3755H10.6006L9.84406 7.358Z"
            fill="#D46C6D"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.5351 19.2421C20.5275 19.5388 20.5209 19.8521 20.5161 20.1754C23.4729 19.9829 24.8307 21.4379 24.844 23.4312C24.8505 24.4068 24.2221 25.3852 23.6319 25.9754C23.2658 26.3415 22.6722 26.3415 22.3061 25.9754C21.94 25.6093 21.94 25.0157 22.3061 24.6496C22.6736 24.282 22.971 23.7411 22.969 23.4437C22.9603 22.1328 21.8619 21.9437 20.5143 22.0546C20.5222 22.577 20.5375 23.0252 20.5575 23.4095C20.6074 23.3421 20.6399 23.2801 20.6532 23.2271C20.7788 22.7248 21.2879 22.4193 21.7901 22.5449C22.2924 22.6706 22.5978 23.1795 22.4722 23.6818C22.2668 24.5035 21.6021 25.1365 20.8389 25.5738C20.8491 25.6017 20.8576 25.6214 20.8639 25.6347C21.1848 26.003 21.17 26.5622 20.8194 26.9128C20.3964 27.3358 19.5009 27.393 19.0809 26.2297C16.7686 26.6841 15.2466 25.3587 15.9858 23.141C16.3957 21.9113 17.3229 21.022 18.6363 20.5483C18.6394 20.2206 18.6445 19.9005 18.651 19.5944C17.6958 19.8143 17.2085 19.9598 16.9146 20.0475C16.6591 20.1238 16.5497 20.1564 16.4062 20.1564C16.0055 20.1564 15.6345 19.8974 15.5105 19.4944C15.3582 18.9995 15.6359 18.4749 16.1308 18.3226C16.1867 18.3055 17.3483 17.9498 18.7121 17.6604C18.7328 17.1669 18.7499 16.8555 18.7517 16.8229C18.7804 16.306 19.2221 15.9108 19.7398 15.9389C20.2567 15.9676 20.6525 16.4099 20.6238 16.9269C20.6235 16.9337 20.6151 17.0865 20.603 17.3469C21.8727 17.2113 23.4501 17.2286 23.8584 18.4535C24.0222 18.9446 23.7567 19.4756 23.2655 19.6393C22.8364 19.7824 22.3768 19.5978 22.1583 19.2212C22.0087 19.1781 21.5734 19.1059 20.5351 19.2421ZM17.6916 24.3363C17.7513 24.4003 18.1044 24.5145 18.7448 24.3842C18.7012 23.8992 18.6686 23.317 18.6502 22.6149C17.7001 23.2325 17.6127 24.2519 17.6916 24.3363Z"
            fill="#D46C6D"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.25 13.125H19.6875V11.3009L22.9969 9.2325C23.5829 8.86623 23.5846 8.00982 22.9969 7.6425L19.6875 5.57414V3.75C19.6875 1.68223 18.0053 0 15.9375 0H3.75C1.68223 0 0 1.68223 0 3.75V13.125C0 15.1928 1.68223 16.875 3.75 16.875H10.3125V18.6991L7.00312 20.7675C6.41707 21.1338 6.41537 21.9902 7.00312 22.3575L10.3125 24.4259V26.25C10.3125 28.3178 11.9947 30 14.0625 30H26.25C28.3178 30 30 28.3178 30 26.25V16.875C30 14.8072 28.3178 13.125 26.25 13.125ZM3.75 15C2.71611 15 1.875 14.1589 1.875 13.125V3.75C1.875 2.71611 2.71611 1.875 3.75 1.875H15.9375C16.9714 1.875 17.8125 2.71611 17.8125 3.75V6.09375C17.8125 6.41701 17.979 6.71742 18.2531 6.88875L20.7311 8.4375L18.2531 9.98625C17.979 10.1576 17.8125 10.458 17.8125 10.7812C17.8125 11.2653 17.8137 11.6583 17.8147 11.9802C17.8188 13.3545 17.819 13.431 17.7062 13.75C17.4418 14.4977 16.731 15 15.9375 15H3.75ZM28.125 26.25C28.125 27.2839 27.2839 28.125 26.25 28.125H14.0625C13.0286 28.125 12.1875 27.2839 12.1875 26.25V23.9062C12.1875 23.583 12.021 23.2826 11.7469 23.1112L9.26889 21.5625L11.7469 20.0138C12.021 19.8424 12.1875 19.542 12.1875 19.2188V16.875H15.9375C17.2913 16.875 18.524 16.1433 19.1852 15H26.25C27.2839 15 28.125 15.8411 28.125 16.875V26.25Z"
            fill="#D46C6D"
          />
          <path
            d="M24.9163 3.00069C25.4343 2.80913 25.699 2.23392 25.5074 1.71592C25.3158 1.19792 24.7406 0.933291 24.2226 1.12485L21.6233 2.08612C21.3632 2.18227 21.1548 2.38204 21.0477 2.63774C20.9406 2.89344 20.9445 3.18212 21.0584 3.43487L22.2039 5.97693C22.4308 6.48045 23.0229 6.7047 23.5264 6.47781C24.0299 6.25091 24.2542 5.65879 24.0273 5.15527L23.8236 4.70332C25.7139 5.71334 27.0003 7.7065 27.0003 9.99912C27.0003 10.291 26.9795 10.5774 26.9395 10.8572C26.8613 11.4039 27.2412 11.9105 27.7879 11.9886C28.3346 12.0668 28.8412 11.687 28.9194 11.1403C28.9727 10.767 29.0003 10.386 29.0003 9.99912C29.0003 6.99383 27.3437 4.37643 24.8937 3.00907L24.9163 3.00069Z"
            fill="#D46C6D"
          />
          <path
            d="M5.97198 24.8439L6.17475 25.2938C4.28554 24.2835 3 22.2909 3 19.999C3 19.7072 3.02077 19.4207 3.06076 19.141C3.13894 18.5943 2.7591 18.0877 2.21237 18.0095C1.66565 17.9313 1.15907 18.3112 1.0809 18.8579C1.02754 19.2311 1 19.6122 1 19.999C1 23.0046 2.6569 25.6222 5.10724 26.9895L5.08295 26.9984C4.56495 27.19 4.30032 27.7652 4.49188 28.2832C4.68343 28.8012 5.25865 29.0658 5.77665 28.8743L8.37603 27.913C8.63604 27.8169 8.84447 27.6171 8.95155 27.3614C9.05864 27.1057 9.05478 26.817 8.94089 26.5643L7.79541 24.0222C7.56851 23.5187 6.97639 23.2944 6.47286 23.5213C5.96934 23.7482 5.74509 24.3403 5.97198 24.8439Z"
            fill="#D46C6D"
          />
        </svg>
      </TooltipReference>
    </>
  );
};

export default TranslateButton;

import React from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'rebass';
import Container from '../../Container';
import Logo from '../Logo';
import Text from '../../Text';
import SecondaryMenu from '../Menu/Secondary';
import SocialMenu from '../Menu/Social';
import MenuSitemap from '../Menu/Sitemap';
import TranslateButton from '../Menu/Translate';

const Wrapper = styled(Container).attrs(() => ({
  py: 6,
}))`
  overflow: hidden;
`;

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <SecondaryMenu />
      <Wrapper>
        <Flex
          flexWrap="wrap"
          justifyContent="space-between"
          alignItems="flex-start"
          m={-3}
        >
          <Box p={3} order={[1, null, 0]} width={[1, null, 1 / 4]}>
            <Logo style={{ width: '100%' }} />
            <Text variant="secondary" mt={4} fontSize={0}>
              Copyright ©{currentYear} Barbara Brennan School of Healing. All
              Rights Reserved. Licensed by the Florida Commission for
              Independent Education - License No. 2897
            </Text>
          </Box>
          <Flex
            p={3}
            flexDirection="column"
            alignItems={['flex-start', null, 'flex-end']}
            width={[1, null, 3 / 4]}
          >
            <MenuSitemap />
            <Box my={3} />
            <SocialMenu />
            <Box my={2} />
            <TranslateButton withTooltip={false} />
          </Flex>
        </Flex>
      </Wrapper>
    </>
  );
};

export default Footer;

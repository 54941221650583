import React, { useState } from 'react';
import { Box, Flex, Button } from 'rebass';
import styled from 'styled-components';
import { display, minHeight } from 'styled-system';
import { FiMenu, FiX } from 'react-icons/fi';
import Container from '../../Container';
import Logo from '../Logo';
import Menu from '../Menu/Primary';
import { Link } from 'gatsby';

const Wrapper = styled(Box)`
  ${minHeight}
  background: white;
`;

Wrapper.defaultProps = {
  py: [3, 4, 5],
};

const IconButton = styled(Button)`
  ${display}
  background: none;
  color: unset;
  border: 0;
  cursor: pointer;
`;

const Navigation = styled(Flex)`
  align-items: center;
  text-transform: uppercase;
  justify-content: flex-end;
`;

const iconProps = {
  size: 24,
};

const Header = React.forwardRef((props, ref) => {
  const [isCollapsed, setVisible] = useState(false);
  return (
    <Wrapper
      ref={ref}
      minHeight={[isCollapsed ? '100vh' : 'auto', null, null, 'auto']}
      {...props}
    >
      <Container maxWidth={1440}>
        <Flex alignItems="center" justifyContent="space-between">
          <Link to="/">
            <Logo />
          </Link>
          <Navigation flex={1}>
            <IconButton
              onClick={() => setVisible(!isCollapsed)}
              display={['auto', null, null, 'none']}
            >
              {isCollapsed ? <FiX {...iconProps} /> : <FiMenu {...iconProps} />}
            </IconButton>
            <Menu width={1} display={['none', null, null, 'inherit']} />
          </Navigation>
        </Flex>
        <Menu
          width={1}
          display={[isCollapsed ? 'initial' : 'none', null, null, 'none']}
        />
      </Container>
    </Wrapper>
  );
});

Header.displayName = 'Header';

export default Header;
